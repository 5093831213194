import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Typography, Stack, Switch, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Header from "../HeaderComponent/Header";
import "./ProfileSetting.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FiEdit } from "react-icons/fi";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
});

const ProfileSetting = () => {
  const [pillsReminder, setPillsReminder] = useState(true);
  const [pillRefillReminder, setPillRefillReminder] = useState(true);

  const handlePillsReminderChange = (event) => {
    setPillsReminder(event.target.checked);
  };

  const handlePillRefillReminderChange = (event) => {
    setPillRefillReminder(event.target.checked);
  };

  const handleEdit = () => {
    alert("Handle Edit");
  };

  return (
    <>
      <Grid display={"flex"} flexDirection={"column"}>
        <Header />
        <ThemeProvider theme={theme}>
          <Box sx={{ padding: "20px" }}>
            <Typography
              variant="h6"
              sx={{
                marginBottom: "15px",
                fontSize: "17px",
                color: "#4E4E55",
                fontWeight: "600",
              }}
            >
              Profile Settings
            </Typography>
            <Box
              display={"flex"}
              // gap={"50px"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ marginBottom: "10px" }}
            >
              <Typography variant="body1" color="textSecondary">
                Name
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body1"
                  sx={{
                    flexGrow: 1,
                    marginRight: "8px",
                    color: "#4E4E55",
                    fontWeight: "500",
                  }}
                >
                  Anant Mehta
                </Typography>
              </Box>
              {/* <IconButton size="small"> */}
              <Typography
                display={"flex"}
                alignItems={"center"}
                fontSize={"14px"}
                gap={"4px"}
                color="textSecondary"
                onClick={handleEdit}
              >
                <FiEdit />
                Edit
              </Typography>
              {/* </IconButton> */}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
              sx={{ marginBottom: "20px" }}
            >
              <Typography variant="body1" color="textSecondary">
                Mobile No.
              </Typography>
              <Typography variant="body1" sx={{ color: "#4E4E55" }}>
                +91 74892 3547
              </Typography>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid #D5DDEC",
                marginBottom: "20px",
              }}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "10px" }}
            >
              <Typography variant="body1" sx={{ color: "#4E4E55" }}>
                Pills Reminder
              </Typography>
              <Switch
                checked={pillsReminder}
                onChange={handlePillsReminderChange}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1" sx={{ color: "#4E4E55" }}>
                Pill Refill Reminder
              </Typography>
              <Switch
                checked={pillRefillReminder}
                onChange={handlePillRefillReminderChange}
              />
            </Stack>
          </Box>
        </ThemeProvider>
      </Grid>
    </>
  );
};

export default ProfileSetting;
