import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const PillReminderNotification = ({ showPillReminderBox, checked, leftTabletValue, remindDaysBefore }) => {
  if (!showPillReminderBox || !checked) return null;

  return (
    <Grid
      sx={{
        bgcolor: 'white',
        width: '92%',
        marginLeft: '5px',
        display: 'flex',
        padding: '5px',
        borderRadius: '5px',
        fontSize: '12.5px',
        fontWeight: '600',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Typography color={'#28A745'} fontSize={'25px'}>
          {leftTabletValue}
        </Typography>
        Tablets Left
      </Box>
      <Box
        sx={{
          bgcolor: '#F8D7DA',
          color: '#B94047',
          padding: '2px 4px',
          borderRadius: '5px',
        }}
      >
        Refill reminder goes in {remindDaysBefore} days
      </Box>
    </Grid>
  );
};

export default PillReminderNotification;
