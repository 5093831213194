import axiosInstance from "../axiosInstance";

export const createSugarRecord = async (sugarRecordData) => {
  try {
    const response = await axiosInstance.post("/api/rem/sugar-record", sugarRecordData);
    return response;
  } catch (error) {
    console.error(
      "Error creating sugar record:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getSugarRecords = async (payload) => {
    try {
      const response = await axiosInstance.post('/api/rem/get-sugar-record', payload);
      return response;
    } catch (error) {
      console.error('Error fetching sugar records:', error.response ? error.response.data : error.message);
      throw error;
    }
  };