import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Stack,
  Switch,
  ThemeProvider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import eveningImg from "../Images/eveningImg.png";
import morningImg from "../Images/morningImg.png";
import afternoonImg from "../Images/afternoonImg.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tabletLogo1 from "../Images/tabletLogo1.svg";
import tabletLogo2 from "../Images/tabletLogo2.svg";
import tabletLogo3 from "../Images/tabletLogo3.svg";
import tabletLogo4 from "../Images/tabletLogo4.svg";
import PillRefillReminder from "../PillRefillReminder/index";
import PillReminderNotification from "../PillReminderNotification/index";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the delete icon

const reminderService = require("../../api/reminders");

const ReminderSection = ({ reminderList, theme, fetchReminders }) => {
  // States for managing pill refill reminder
  const [showPillRefillReminderSection, setShowPillRefillReminderSection] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [leftTabletValue, setLeftTabletValue] = useState("");
  const [remindDaysBefore, setRemindDaysBefore] = useState("");
  const [showPillReminderBox, setShowPillReminderBox] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  // States for managing existing reminders
  const [editState, setEditState] = useState({ isOpen: false, reminderId: null, partOfDay: null });
  const [editSelectedTime, setEditSelectedTime] = useState("");

  // Times of day for selection
  const morningTimes = ["6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM"];
  const afternoonTimes = ["12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM"];
  const eveningTimes = ["6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM"];

  useEffect(() => {
    if (!checked) {
      setShowPillRefillReminderSection(false);
      setShowPillReminderBox(false);
    }
  }, [checked]);

  const handleDateChange = (event) => setDateValue(event.target.value);
  const handleLeftTabletChange = (event) => setLeftTabletValue(event.target.value);
  const handleRemindDayBeforeChange = (event) => setRemindDaysBefore(event.target.value);

  const getReminderId = (reminders) => {
    const timesOfDay = ["morning", "afternoon", "evening"];
    for (const time of timesOfDay) {
      if (reminders[time] && reminders[time].length > 0) {
        return reminders[time][0].reminder_id;
      }
    }
    return null;
  };

  const reminder_id = reminderList && getReminderId(reminderList);

  const handleSavePillRefillReminder = async () => {
    if (!dateValue || !leftTabletValue || !remindDaysBefore) {
      toast.error("All fields are required");
      return;
    }

    const data = {
      number_of_tablets: leftTabletValue,
      reminder_days_before_refill: remindDaysBefore,
      refill_reminder_date: dateValue,
      reminder_id: reminder_id,
    };

    const response = await reminderService.refillReminder(data);
    if (response.status === 200) {
      toast.success("Refill Reminder set successfully");
      fetchRefillRemiders();
    }
    setShowPillRefillReminderSection(false);
    setShowPillReminderBox(true);
    setChecked(true);
  };

  const userId = useSelector((state) => state.auth?.userId || null);
  const phone_number = useSelector((state) => state.auth?.phone_number || null);

  const fetchRefillRemiders = async () => {
    try {
      if (userId) {
        const reminders = await reminderService.getRefillReminder(userId);
        if (reminders && reminders.length > 0) {
          setChecked(true);
          setShowPillReminderBox(true);
          setShowPillRefillReminderSection(false);
          setDateValue(reminders[0].next_refill_date);
          setLeftTabletValue(reminders[0].remaining_tablets);
          setRemindDaysBefore(reminders[0].days_remaining);
        } else {
          setChecked(false);
        }
      }
    } catch (error) {
      console.error("Error in fetchRefillReminders:", error.message);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchRefillRemiders();
    }
  }, [userId]);

  const handleEditTime = (reminderId, partOfDay) => {
    setEditState({ isOpen: true, reminderId, partOfDay });
  };

  const handleEditSelectedTime = (time) => setEditSelectedTime(time);

  const convertTo24HourFormat = (time) => {
    const [hourStr, period] = time.split(" ");
    let hour = parseInt(hourStr, 10);
    let minutes = 0;

    // Adjust the hour for 12 AM/PM cases
    if (period === "PM" && hour !== 12) {
      hour += 12;
    }
    if (period === "AM" && hour === 12) {
      hour = 0;
    }

    let date = new Date();
    date.setHours(hour);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    date.setMinutes(date.getMinutes() - 5);

    let hour24 = date.getHours();
    let minute24 = date.getMinutes();

    return `${hour24 < 10 ? "0" : ""}${hour24}:${minute24 < 10 ? "0" : ""}${minute24}:00`;
  };

  const handleEditTimeSave = async (partOfDay) => {
    if (!editState.reminderId) return;

    const convertedTime = convertTo24HourFormat(editSelectedTime);
    const data = { reminder_id: editState.reminderId, new_time: convertedTime, partOfDay };

    try {
      const response = await reminderService.updateReminder(data);
      if (response.status === 200) {
        toast.success("Reminder time updated successfully");
        setEditState({ isOpen: false, reminderId: null, partOfDay: null });
        fetchReminders();
        setEditSelectedTime("");
      }
    } catch (error) {
      toast.error("Error updating reminder time");
    }
  };

  const pauseResumeReminderFunCall = async (id, partOfDay, isActive) => {
    const data = { reminder_id: id, reminder_time_type: partOfDay };

    try {
      const response = await reminderService.pauseReminder(data);
      if (response.status === 200) {
        toast.success(`Reminder ${isActive ? "paused" : "resumed"} successfully`);
        fetchReminders();
      }
    } catch (error) {
      toast.error(`Error ${isActive ? "pausing" : "resuming"} reminder`);
    }
  };

  const getTimesByPartOfDay = (partOfDay) => {
    switch (partOfDay) {
      case "morning":
        return morningTimes;
      case "afternoon":
        return afternoonTimes;
      case "evening":
        return eveningTimes;
      default:
        return [];
    }
  };

  const formatReminderTime = (time) => {
    let [hour, minute] = time.split(":").map(Number);
    const period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    minute += 5;

    if (minute >= 60) {
      minute -= 60;
      hour += 1;
    }

    return `${hour}:${minute < 10 ? "0" : ""}${minute} ${period}`;
  };

  // const logoMap = {
  //   1: tabletLogo1,
  //   2: tabletLogo2,
  //   3: tabletLogo3,
  //   4: tabletLogo4,
  // };

  const logoMap = {
    1: "Glimisave M0.5X",
    2: "Glimisave M1X",
    3: "Glimisave M2X",
  };

  const getMedicationId = (reminders) => {
    const timesOfDay = ["morning", "afternoon", "evening"];
    for (const time of timesOfDay) {
      if (reminders[time] && reminders[time].length > 0) {
        return reminders[time][0].medication_id;
      }
    }
    return null;
  };

  const handleToggleChange = async (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    if (!isChecked) {
      try {
        const response = await reminderService.deleteRefillReminder({ reminder_id: reminder_id });
        if (response.status === 200) {
          toast.success("Refill Reminder deleted successfully");
          setChecked(false);
          setShowPillRefillReminderSection(false);
          setShowPillReminderBox(false);
          setDateValue("");
          setLeftTabletValue("");
          setRemindDaysBefore("");
        }
      } catch (error) {
        toast.error("Error deleting refill reminder");
      }
    } else {
      setShowPillRefillReminderSection(true);
      setShowPillReminderBox(false);
    }
  };

  const getImageForPartOfDay = (partOfDay) => {
    switch (partOfDay) {
      case "morning":
        return morningImg;
      case "afternoon":
        return afternoonImg;
      case "evening":
        return eveningImg;
      default:
        return null;
    }
  };

  const handleDeleteReminder = async () => {
    try {
      let response = null;

      if (userId) {
        response = await reminderService.deleteReminders(userId);
      }

      console.log("response",response)

      if (response.status === 200 || response.message=="Reminder deleted successfully.") {
        fetchReminders();
        toast.success("Reminder deleted successfully");
      } else {
        toast.error("Failed to delete reminder. Please try again later.");
      }
    } catch (error) {
      console.error("Error in deleteReminder:", error);

      if (error.response) {
        const { status, data } = error.response;
        switch (status) {
          case 400:
            toast.error(data.message || "Bad Request. Please check your input.");
            break;
          case 401:
            toast.error(data.message || "Unauthorized. Please log in again.");
            break;
          case 403:
            toast.error(data.message || "Forbidden. You don't have permission.");
            break;
          case 404:
            toast.error(data.message || "Reminder not found. Please try again.");
            break;
          case 500:
            toast.error(data.message || "Server error. Please try again later.");
            break;
          default:
            toast.error(data.message || "An unknown error occurred. Please try again.");
        }
      } else if (error.request) {
        toast.error("Network error. Please check your internet connection.");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
    finally {
      handleClose();
    }
  };

  const medicationId = reminderList && getMedicationId(reminderList);
  const logoSrc = logoMap[medicationId];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid height={"100vh"} paddingTop={"130px"} paddingLeft={"10px"} sx={{ bgcolor: "#F5F5F5" }}>
      <Stack>
        <Box display="flex" justifyContent="space-between" padding="10px">
          <Box display="flex" alignItems="center">
            <Typography color="#4E4E55" fontSize="18px" fontWeight="600">
              {logoSrc}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <IconButton aria-label="delete" style={{ color: "red" }} onClick={handleClickOpen}>
              <DeleteIcon />
            </IconButton>
            <Typography color="red" fontSize="14px" fontWeight="600" marginRight="8px">
              Delete Reminders
            </Typography>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Delete Pill Reminder?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this pill reminder? This will also remove the
                  refill reminder.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleDeleteReminder}
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
        {Object.keys(reminderList).map((partOfDay) =>
          reminderList[partOfDay].map((reminder) => {
            const isActive =
              reminder[
                `reminder_active_${partOfDay === "morning" ? 1 : partOfDay === "afternoon" ? 2 : 3}`
              ];
            return (
              <Grid key={reminder.reminder_id}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  padding={"10px 10px"}
                  sx={{
                    bgcolor: "white",
                    marginTop: "10px",
                    borderRadius: "10px",
                    width: "88%",
                  }}
                >
                  <Box display={"flex"}>
                    <img
                      src={getImageForPartOfDay(partOfDay)}
                      height={"38px"}
                      style={{ marginRight: "10px" }}
                    />
                    <Box>
                      <Typography
                        color={"#707179"}
                        fontSize={"13px"}
                        width={"100px"}
                        fontWeight={"600"}
                      >
                        {`${partOfDay.charAt(0).toUpperCase() + partOfDay.slice(1)} Dose`}
                      </Typography>
                      <Typography color={"#4E4E55"} sx={{ fontSize: "13px", fontWeight: "600" }}>
                        {formatReminderTime(
                          reminder[
                            `reminder_time_${
                              partOfDay === "morning" ? 1 : partOfDay === "afternoon" ? 2 : 3
                            }`
                          ]
                        )}
                      </Typography>
                      <button
                        style={{
                          color: "#2B72E6",
                          backgroundColor: "white",
                          border: "none",
                          padding: "0px",
                          marginTop: "10px",
                          fontSize: "13px",
                        }}
                        onClick={() => handleEditTime(reminder.reminder_id, partOfDay)}
                      >
                        Edit timing
                      </button>
                      {editState.isOpen &&
                        editState.reminderId === reminder.reminder_id &&
                        editState.partOfDay === partOfDay && (
                          <Box marginTop="10px">
                            {getTimesByPartOfDay(partOfDay).map((time, index) => (
                              <Button
                                key={index}
                                variant={editSelectedTime === time ? "contained" : "outlined"}
                                sx={{
                                  margin: "5px 8px",
                                  width: "70px",
                                  fontSize: "12px",
                                  borderRadius: "20px",
                                  marginLeft: "0px",
                                  marginRight: "5px",
                                  border: "1px solid #FF725E",
                                  color: editSelectedTime === time ? "white" : "#FF725E",
                                  fontWeight: "600",
                                  backgroundColor:
                                    editSelectedTime === time ? "#FF725E" : "transparent",
                                  "&:hover": {
                                    backgroundColor:
                                      editSelectedTime === time
                                        ? "#FF725E"
                                        : "rgba(255, 114, 94, 0.04)",
                                  },
                                }}
                                onClick={() => handleEditSelectedTime(time)}
                                disabled={editSelectedTime === time}
                              >
                                {time}
                              </Button>
                            ))}
                            <Button
                              sx={{
                                border: "2px solid #0059A5",
                                color: "#0059A5",
                                borderRadius: "7px",
                                textAlign: "center",
                                fontWeight: "500",
                                margin: "10px 0px 0px 0px",
                                width: "100%",
                              }}
                              onClick={() => handleEditTimeSave(partOfDay)}
                            >
                              Save
                            </Button>
                          </Box>
                        )}
                    </Box>
                  </Box>
                  {!editState.isOpen ||
                  editState.reminderId !== reminder.reminder_id ||
                  editState.partOfDay !== partOfDay ? (
                    <Button
                      sx={{
                        fontSize: "10px",
                        padding: "0px 10px",
                        margin: "auto 0px",
                        color: isActive ? "#FF4E4E" : "#2B72E6",
                        border: `2px solid ${isActive ? "#FF4E4E" : null}`,
                        height: "30px",
                        borderRadius: "15px",
                        fontWeight: "600",
                        backgroundColor: isActive ? "transparent" : "#00A859",
                        color: isActive ? "#FF4E4E" : "#FFFFFF",
                      }}
                      onClick={() =>
                        pauseResumeReminderFunCall(reminder.reminder_id, partOfDay, isActive)
                      }
                    >
                      {isActive ? "Pause Reminder" : "Resume Reminder"}
                    </Button>
                  ) : null}
                </Box>
              </Grid>
            );
          })
        )}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          padding={"15px"}
          alignItems={"center"}
        >
          <Typography color={"#95969C"} fontWeight={"600"}>
            Refill Reminder
          </Typography>
          <ThemeProvider theme={theme}>
            <Switch
              checked={checked}
              onChange={handleToggleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </ThemeProvider>
        </Box>
        {showPillRefillReminderSection ? (
          <PillRefillReminder
            showPillRefillReminderSection={showPillRefillReminderSection}
            dateValue={dateValue}
            leftTabletValue={leftTabletValue}
            remindDaysBefore={remindDaysBefore}
            handleDateChange={handleDateChange}
            handleLeftTabletChange={handleLeftTabletChange}
            handleRemindDayBeforeChange={handleRemindDayBeforeChange}
            handleSavePillRefillReminder={handleSavePillRefillReminder}
          />
        ) : (
          ""
        )}
      </Stack>

      {showPillReminderBox && checked ? (
        <PillReminderNotification
          showPillReminderBox={showPillReminderBox}
          checked={checked}
          leftTabletValue={leftTabletValue}
          remindDaysBefore={remindDaysBefore}
        />
      ) : (
        ""
      )}

      <Typography fontSize={"12px"} padding={"0px 10px"} marginTop={"190px"}>
        We'll send an SMS to{" "}
        <span style={{ color: "#2B72E6", margin: "0px" }}> {phone_number} </span> 15 minutes before
        your scheduled dose
      </Typography>
    </Grid>
  );
};

export default ReminderSection;
