import React, { useState } from "react";
import { Grid, Typography, Stack, Box, Checkbox, Button } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterPopUp from "../RegisterPopUp/index";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { FaChevronRight } from "react-icons/fa6";
const reminderService = require("../../api/reminders");

const AddMedicineSection = ({
  tablets,
  doseLogo,
  setShowAddMedicienSection,
  fetchReminders,
}) => {
  const [selectedTabletId, setSelectedTabletId] = useState(null);
  const [selectedDosage, setSelectedDosage] = useState(null);
  const [selectedTimes, setSelectedTimes] = useState({
    morning: null,
    afternoon: null,
    evening: null,
  });
  const [loading, setLoading] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const userId = useSelector((state) => state.auth?.userId || null);

  const morningTimes = ["6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM"];
  const afternoonTimes = ["12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM"];
  const eveningTimes = ["6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM"];

  const convertTo24HourFormat = (time) => {
    const [hourStr, period] = time.split(" ");
    let hour = parseInt(hourStr, 10);
    let minutes = 0;

    // Adjust the hour for 12 AM/PM cases
    if (period === "PM" && hour !== 12) {
      hour += 12;
    }
    if (period === "AM" && hour === 12) {
      hour = 0;
    }

    let date = new Date();
    date.setHours(hour);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    date.setMinutes(date.getMinutes() - 5);

    let hour24 = date.getHours();
    let minute24 = date.getMinutes();

    return `${hour24 < 10 ? "0" : ""}${hour24}:${
      minute24 < 10 ? "0" : ""
    }${minute24}:00`;
  };

  const handleSelectionChange = (tabletId) => {
    setSelectedTabletId(tabletId);
  };

  const handleDosageChange = (dosage) => {
    setSelectedDosage(dosage);
    setSelectedTimes({
      morning: null,
      afternoon: null,
      evening: null,
    });
  };

  const handleSelectedTime = (time, period) => {
    const formattedTime = convertTo24HourFormat(time);

    if (selectedDosage === "single") {
      setSelectedTimes({
        morning:
          period === "morning"
            ? selectedTimes.morning === formattedTime
              ? null
              : formattedTime
            : selectedTimes.morning,
        afternoon:
          period === "afternoon"
            ? selectedTimes.afternoon === formattedTime
              ? null
              : formattedTime
            : selectedTimes.afternoon,
        evening:
          period === "evening"
            ? selectedTimes.evening === formattedTime
              ? null
              : formattedTime
            : selectedTimes.evening,
      });
    } else if (selectedDosage === "twice") {
      const selectedCount = Object.values(selectedTimes).filter(Boolean).length;
      const isCurrentlySelected = selectedTimes[period] === formattedTime;

      if (isCurrentlySelected) {
        setSelectedTimes({
          ...selectedTimes,
          [period]: null,
        });
      } else if (
        selectedCount < 2 ||
        (selectedCount === 2 && isCurrentlySelected)
      ) {
        setSelectedTimes({
          ...selectedTimes,
          [period]: formattedTime,
        });
      }
    } else if (selectedDosage === "thrice") {
      setSelectedTimes({
        ...selectedTimes,
        [period]:
          selectedTimes[period] === formattedTime ? null : formattedTime,
      });
    }
  };

  const savedSeelecetdTimes = {
    user_id: userId,
    reminder_time_1: selectedTimes.morning || null,
    reminder_time_2: selectedTimes.afternoon || null,
    reminder_time_3: selectedTimes.evening || null,
    medication_id: selectedTabletId || null,
    dose_frequency: selectedDosage || null,
  };

  const filteredSavedTimeObject = Object.fromEntries(
    Object.entries(savedSeelecetdTimes).filter(([key, value]) => value !== null)
  );

  const handleSaveMedicine = async () => {
    if (userId === null) {
      toast.error("Please login to save reminder");
      setShowRegisterPopup(true);
      return;
    }
  
    try {
      const doseFrequency = filteredSavedTimeObject.dose_frequency;
      const selectedTimes = [
        savedSeelecetdTimes.reminder_time_1,
        savedSeelecetdTimes.reminder_time_2,
        savedSeelecetdTimes.reminder_time_3,
      ].filter((time) => time !== null);
  
      let requiredTimes;
      if (doseFrequency === "single") {
        requiredTimes = 1;
      } else if (doseFrequency === "twice") {
        requiredTimes = 2;
      } else if (doseFrequency === "thrice") {
        requiredTimes = 3;
      }
  
      if (selectedTimes.length !== requiredTimes) {
        toast.error(
          `Please select exactly ${requiredTimes} reminder time${
            requiredTimes > 1 ? "s" : ""
          }`
        );
        return;
      }
      setLoading(true)
      const response = await reminderService.createReminder(filteredSavedTimeObject);
  
      if (response.status === 201) {
        toast.success("Reminder saved successfully");
        fetchReminders();
        setShowAddMedicienSection(false);
      } else {
        toast.error("Failed to save reminder. Please try again later.");
      }
    } catch (error) {
      console.error("Error in createReminder:", error);
  
      if (error.response) {
        const { status, data } = error.response;
        switch (status) {
          case 400:
            toast.error(data.message || "Bad Request. Please check your input.");
            break;
          case 401:
            toast.error(data.message || "Unauthorized. Please log in again.");
            break;
          case 403:
            toast.error(data.message || "Forbidden. You don't have permission.");
            break;
          case 404:
            toast.error(data.message || "Not Found. Please try again.");
            break;
          case 500:
            toast.error(data.message || "Server error. Please try again later.");
            break;
          default:
            toast.error(data.message || "An unknown error occurred. Please try again.");
        }
      } else if (error.request) {
        toast.error("Network error. Please check your internet connection.");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
    finally{
      setLoading(false)
    }
  };
  

  return (
    <>
      {" "}
      {showRegisterPopup && (
        <RegisterPopUp
          open={showRegisterPopup}
          onClose={() => setShowRegisterPopup(false)}
        />
      )}
      <Grid
        container
        display={"flex"}
        paddingRight={"25px"}
        paddingLeft={"25px"}
        paddingTop={"50px"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        gap={"20px"}
        paddingBottom={"20px"}
      >
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"15px"}
          marginTop={"48px"}
        >
          <Typography fontWeight={"600"} color={"#4E4E55"} fontSize={"17px"}>
            Select Your Tablet
          </Typography>
          <Stack>
            {tablets.map((tablet) => (
              <Box
                key={tablet.medication_id}
                display="flex"
                alignItems="center"
                onClick={() => handleSelectionChange(tablet.medication_id)}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  checked={selectedTabletId === tablet.medication_id}
                  onChange={() => handleSelectionChange(tablet.medication_id)}
                  onClick={(e) => e.stopPropagation()}
                  sx={{ paddingLeft: "0px" }}
                />
                {/* <img src={tablet.tabletLogo} height={"14px"} alt="tablet" /> */}
                <Typography
                  marginRight={"20px"}
                  color={"#0059A5"}
                  fontSize={"15px"}
                  fontWeight={"600"}
                >
                  {tablet.tabletLogo}
                </Typography>                
              </Box>
            ))}
          </Stack>
        </Grid>
        <Grid item display={"flex"} flexDirection={"column"} gap={"15px"}>
          <Typography fontWeight={"600"} color={"#4E4E55"} fontSize={"17px"}>
            Select Your Dosage
          </Typography>
          <Stack>
            <Box
              display={"flex"}
              alignItems={"center"}
              onClick={() => handleDosageChange("single")}
            >
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                checked={selectedDosage === "single"}
                sx={{ paddingLeft: "0px" }}
              />
              <Typography
                marginRight={"20px"}
                color={"#0059A5"}
                fontSize={"15px"}
                fontWeight={"600"}
              >
                Once a day
              </Typography>
              <img src={doseLogo} height={"24px"} alt="dose" />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              onClick={() => handleDosageChange("twice")}
            >
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                checked={selectedDosage === "twice"}
                sx={{ paddingLeft: "0px" }}
              />
              <Typography
                marginRight={"18px"}
                color={"#0059A5"}
                fontSize={"15px"}
                fontWeight={"600"}
              >
                Twice a day
              </Typography>
              <Box display={"flex"} gap={"5px"}>
                <img src={doseLogo} height={"24px"} alt="dose" />
                <img src={doseLogo} height={"24px"} alt="dose" />
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              onClick={() => handleDosageChange("thrice")}
            >
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                checked={selectedDosage === "thrice"}
                sx={{ paddingLeft: "0px" }}
              />
              <Typography
                marginRight={"15px"}
                color={"#0059A5"}
                fontSize={"15px"}
                fontWeight={"600"}
              >
                Thrice a day
              </Typography>
              <Box display={"flex"} gap={"5px"}>
                <img src={doseLogo} height={"24px"} alt="dose" />
                <img src={doseLogo} height={"24px"} alt="dose" />
                <img src={doseLogo} height={"24px"} alt="dose" />
              </Box>
            </Box>
          </Stack>
        </Grid>

        <Grid item>
          <Stack>
            <Typography fontWeight={"600"} color={"#4E4E55"} fontSize={"17px"}>
              Select Your Timing
            </Typography>

            {/* Morning */}
            <Typography
              color={"#0059A5"}
              fontWeight={"600"}
              fontSize={"15px"}
              marginTop={"20px"}
            >
              Morning
              <Box marginTop="10px">
                {morningTimes.map((time, index) => (
                  <Button
                    key={index}
                    variant={
                      selectedTimes.morning === convertTo24HourFormat(time)
                        ? "contained"
                        : "outlined"
                    }
                    sx={{
                      margin: "5px 8px",
                      width: "70px",
                      fontSize: "12px",
                      borderRadius: "20px",
                      marginLeft: "0px",
                      marginRight: "10px",
                      border: "1px solid #FF725E",
                      color:
                        selectedTimes.morning === convertTo24HourFormat(time)
                          ? "white"
                          : "#FF725E",
                      fontWeight: "600",
                      backgroundColor:
                        selectedTimes.morning === convertTo24HourFormat(time)
                          ? "#FF725E"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedTimes.morning === convertTo24HourFormat(time)
                            ? "#FF725E"
                            : "rgba(255, 114, 94, 0.04)",
                      },
                    }}
                    onClick={() => handleSelectedTime(time, "morning")}
                    disabled={
                      selectedDosage === "twice" &&
                      selectedTimes.morning !== null &&
                      selectedTimes.afternoon !== null &&
                      selectedTimes.evening !== null
                    }
                  >
                    {time}
                  </Button>
                ))}
              </Box>
            </Typography>

            {/* Afternoon */}
            <Typography
              color={"#0059A5"}
              fontWeight={"600"}
              fontSize={"15px"}
              marginTop={"20px"}
            >
              Afternoon
              <Box marginTop="10px">
                {afternoonTimes.map((time, index) => (
                  <Button
                    key={index}
                    variant={
                      selectedTimes.afternoon === convertTo24HourFormat(time)
                        ? "contained"
                        : "outlined"
                    }
                    sx={{
                      margin: "5px 8px",
                      width: "70px",
                      fontSize: "12px",
                      borderRadius: "20px",
                      marginLeft: "0px",
                      marginRight: "10px",
                      border: "1px solid #FF725E",
                      color:
                        selectedTimes.afternoon === convertTo24HourFormat(time)
                          ? "white"
                          : "#FF725E",
                      fontWeight: "600",
                      backgroundColor:
                        selectedTimes.afternoon === convertTo24HourFormat(time)
                          ? "#FF725E"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedTimes.afternoon ===
                          convertTo24HourFormat(time)
                            ? "#FF725E"
                            : "rgba(255, 114, 94, 0.04)",
                      },
                    }}
                    onClick={() => handleSelectedTime(time, "afternoon")}
                    disabled={
                      selectedDosage === "twice" &&
                      selectedTimes.morning !== null &&
                      selectedTimes.afternoon !== null &&
                      selectedTimes.evening !== null
                    }
                  >
                    {time}
                  </Button>
                ))}
              </Box>
            </Typography>

            {/* Evening */}
            <Typography
              color={"#0059A5"}
              fontWeight={"600"}
              fontSize={"15px"}
              marginTop={"20px"}
            >
              Evening
              <Box marginTop="10px">
                {eveningTimes.map((time, index) => (
                  <Button
                    key={index}
                    variant={
                      selectedTimes.evening === convertTo24HourFormat(time)
                        ? "contained"
                        : "outlined"
                    }
                    sx={{
                      margin: "5px 8px",
                      width: "70px",
                      fontSize: "12px",
                      borderRadius: "20px",
                      marginLeft: "0px",
                      marginRight: "10px",
                      border: "1px solid #FF725E",
                      color:
                        selectedTimes.evening === convertTo24HourFormat(time)
                          ? "white"
                          : "#FF725E",
                      fontWeight: "600",
                      backgroundColor:
                        selectedTimes.evening === convertTo24HourFormat(time)
                          ? "#FF725E"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedTimes.evening === convertTo24HourFormat(time)
                            ? "#FF725E"
                            : "rgba(255, 114, 94, 0.04)",
                      },
                    }}
                    onClick={() => handleSelectedTime(time, "evening")}
                    disabled={
                      selectedDosage === "twice" &&
                      selectedTimes.morning !== null &&
                      selectedTimes.afternoon !== null &&
                      selectedTimes.evening !== null
                    }
                  >
                    {time}
                  </Button>
                ))}
              </Box>
            </Typography>
          </Stack>
        </Grid>

        <Button
          onClick={handleSaveMedicine}
          disabled={loading}
          sx={{
            bgcolor: loading ? "#ccc" : "#0059A5",
            color: "white",
            borderRadius: "7px",
            textTransform: "none",
          }}
          // textTransform={"none"}
        >
          Save <FaChevronRight />
        </Button>
      </Grid>
    </>
  );
};

export default AddMedicineSection;
