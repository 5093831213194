import axiosInstance from "../axiosInstance";

export const createReminder = async (reminderData) => {
  try {
    const response = await axiosInstance.post("/api/rem/reminder", reminderData);
    return response;
  } catch (error) {
    console.error("Error creating reminder:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getReminder = async (user_id) => {
  try {
    const response = await axiosInstance.get(`/api/rem/reminders/${user_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching reminder:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteReminders = async (userId) => {
  console.log(userId)
  try {
    const response = await axiosInstance.delete(`/api/rem/reminders/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching reminder:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const updateReminder = async (reminderData) => {
  try {
    const response = await axiosInstance.put("/api/rem/reminder", reminderData);
    return response;
  } catch (error) {
    console.error("Error updating reminder:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const pauseReminder = async (reminderData) => {
  try {
    const response = await axiosInstance.post("/api/rem/pause-reminder", reminderData);
    return response;
  } catch (error) {
    console.error("Error pausing reminder:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const refillReminder = async (reminderData) => {
  try {
    const response = await axiosInstance.post("/api/rem/refill-reminder", reminderData);
    return response;
  } catch (error) {
    console.error(
      "Error refilling reminder:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deleteRefillReminder = async (reminderData) => {
  try {
    const response = await axiosInstance.post("/api/rem/delete-refill-reminder", reminderData);
    return response;
  } catch (error) {
    console.error(
      "Error deleting refill reminder:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}

export const getRefillReminder = async (user_id) => {
  try {
    const response = await axiosInstance.get(`/api/rem/refill-reminder/${user_id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching refill reminder:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
