import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../Popup/index";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import editImg from "../Images/editImg.svg";
import InputOtpComponent from "../InputOtp/InputOtpComponent";
import axiosInstance from "../../axiosInstance";
import { useDispatch } from "react-redux";
import { login } from "../../reducers/authSlice";

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

const RegisterPopUp = ({ open, onClose }) => {
  const [openFirstPopup, setOpenFirstPopup] = useState(open);
  const [openSecondPopup, setOpenSecondPopup] = useState(false);
  const [username, setUsername] = useState("");
  const [registerMobileNumber, setRegisterMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const [onTimerComplete, setOnTimerComplete] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setOpenFirstPopup(open);
  }, [open]);

  const handleOpenFirstPopup = () => setOpenFirstPopup(true);
  const handleCloseFirstPopup = () => {
    setOpenFirstPopup(false);
    onClose();
  };

  const handleNext = () => {
    setOpenFirstPopup(false);
    setOpenSecondPopup(true);
  };

  const handleNavigateHome = () => {
    setOpenSecondPopup(false);
    onClose();
    navigate("/home");
  };

  const handleGetOtp = async () => {
    const phoneNumberPattern = /^\d{10}$/;
  
    if (!username || !registerMobileNumber) {
      toast.error("All fields are required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Ensure early return on validation failure
    }
  
    if (!phoneNumberPattern.test(registerMobileNumber)) {
      toast.error("Please enter a valid 10-digit phone number", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Ensure early return on validation failure
    }
  
    try {
      const response = await axiosInstance.post("/api/auth/register-using-phone-no", {
        phone_number: registerMobileNumber,
        name: username,
      });
  
      if (response.status === 200) {
        setOpenFirstPopup(false);
        setOpenSecondPopup(true);
        startTimer();
      } else if (response.status === 409) {
        toast.error("User already exists", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);

      if (error.response && error.response.status === 409) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      else if(error.response){
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } 
    }
  };  

  const handleVerifyOtp = async () => {
    try {
      const response = await axiosInstance.post("/api/auth/validate-otp-for-reg", {
        phone_number: registerMobileNumber,
        otp,
        name: username,
      });
  
      if (response.status === 200) {
        dispatch(
          login({
            userId: response.data.user.user_id,
            userName: response.data.user.name,
            phone_number: response.data.user.phone_number,
          })
        );
        localStorage.setItem("token", response.data.token);
        setOpenSecondPopup(false);
        onClose();
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      if (error.response && error.response.status === 400) {
        toast.error("Invalid OTP or other validation error", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };  

  const handleResendOTP = async () => {
    const response = await axiosInstance.post("/api/auth/register-using-phone-no", {
      phone_number: registerMobileNumber,
      name: username,
    });

    if (response.status === 200) {
      setOpenFirstPopup(false);
      setOpenSecondPopup(true);
      startTimer();
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setOnTimerComplete(false);
    startTimer();
  };

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      setOnTimerComplete(true);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isActive, seconds]);

  const startTimer = () => {
    setIsActive(true);
    setSeconds(30);
  };

  const handleEditNumber = () => {
    setOpenSecondPopup(false);
    setOpenFirstPopup(true);
  };

  return (
    <>
      <Popup
        open={openFirstPopup}
        onClose={handleCloseFirstPopup}
        content={
          <>
            <Typography fontWeight={"600"} color={"#4E4E55"} marginBottom={"30px"}>
              We need the following information to send you reminders
            </Typography>
            <Box>
              <label style={{ color: "#707179", fontSize: "13px", fontWeight: "600" }}>
                Your Name
              </label>
              <input
                placeholder="Enter your name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{
                  padding: "8px",
                  fontSize: "13px",
                  width: "92%",
                  marginBottom: "20px",
                  marginTop: "4px",
                  border: "1px solid #64748B",
                  borderRadius: "5px",
                }}
              />

              <label style={{ color: "#707179", fontSize: "13px", fontWeight: "600" }}>
                Your Mobile Number
              </label>
              <input
                placeholder="Enter your mobile number"
                type="number"
                value={registerMobileNumber}
                onChange={(e) => setRegisterMobileNumber(e.target.value)}
                style={{
                  padding: "8px",
                  fontSize: "13px",
                  width: "92%",
                  marginBottom: "20px",
                  marginTop: "4px",
                  border: "1px solid #64748B",
                  borderRadius: "5px",
                }}
              />
            </Box>
            <Button
              onClick={handleGetOtp}
              sx={{
                bgcolor: "#0059A5",
                color: "white",
                width: "98%",
                marginTop: "20px",
                borderRadius: "6px",
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              Get OTP
            </Button>
          </>
        }
      />

      <Popup
        open={openSecondPopup}
        onClose={() => setOpenSecondPopup(false)}
        content={
          <>
            <Typography
              marginTop={"40px"}
              color={"#332D2D"}
              sx={{ marginTop: "6px", marginBottom: "20px" }}
            >
              <p
                style={{ color: "#707179", fontSize: "13px", display: "block", margin: "8px 0px" }}
              >
                We just sent a 4-digit code to
              </p>
              <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                <Typography
                  color="#78A5FE"
                  sx={{
                    textDecoration: "underline",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  +91 {registerMobileNumber}
                </Typography>
                <Typography
                  onClick={() => handleEditNumber()}
                  color="#B2B2B2"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                    fontSize: "12px",
                  }}
                >
                  <img src={editImg} alt="Edit Img" height="11px" /> Edit
                </Typography>
              </Box>
            </Typography>

            <ThemeProvider theme={theme}>
              <InputOtpComponent value={otp} onChange={setOtp} length={4} />
            </ThemeProvider>
            <Button
              onClick={handleVerifyOtp}
              sx={{
                bgcolor: "#0059A5",
                color: "white",
                width: "98%",
                marginTop: "20px",
                borderRadius: "6px",
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              Verify Mobile No.
            </Button>

            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                gap: "3px",
                fontSize: "11px",
                marginTop: "20px",
              }}
            >
              Didn't get the OTP?{" "}
              {onTimerComplete ? (
                <Typography
                  sx={{
                    ":hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleResendOTP()}
                  color={"#3B82F6"}
                  fontSize={"11px"}
                >
                  Resend OTP
                </Typography>
              ) : (
                <Typography color={"#A6A6A6"} fontSize={"11px"}>
                  Resend OTP in {seconds}s
                </Typography>
              )}
            </Typography>
          </>
        }
      />
    </>
  );
};

export default RegisterPopUp;
