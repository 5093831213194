import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button, Stack, Typography, Grid, CircularProgress } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import tabletLogo1 from "../Images/tabletLogo1.svg";
import tabletLogo2 from "../Images/tabletLogo2.svg";
import tabletLogo3 from "../Images/tabletLogo3.svg";
import tabletLogo4 from "../Images/tabletLogo4.svg";
import doseLogo from "../Images/doseLogo.svg";
import eveningImg from "../Images/eveningImg.png";
import Switch from "@mui/material/Switch";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import videoImg from "../Images/videoImg.png";
import pdfImg from "../Images/pdfImg.png";
import { useSelector } from "react-redux";
import LearningSection from "../LearningSection/index";
import AddMedicineSection from "../AddMedicineSection/index";
import ReminderSection from "../ReminderSection/index";
import "./index.scss";
import Header from "../HeaderComponent/Header";
import SugarReading from "../SugarReading/SugarReading";

const reminderService = require("../../api/reminders");

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#ffffff",
        },
        track: {
          backgroundColor: "#d6d6d6",
        },
        checked: {
          "& + .MuiSwitch-track": {
            backgroundColor: "#1976d2", // change this color to your desired color when checked
          },
        },
      },
    },
  },
});

const Index = () => {
  const [value, setValue] = useState(0);
  const [showAddMedicienSection, setShowAddMedicienSection] = useState(false);
  const [reminderList, setReminderList] = useState({
    morning: [],
    afternoon: [],
    evening: [],
  });
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [articleTabOpen, setArticleTabOpen] = useState(true);
  const [videosTabOpen, setVideosTabOpen] = useState(false);

  const handleToaggleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleDownload = (pdfUrl) => {
    if (!pdfUrl) {
      console.error("No PDF URL provided");
      return;
    }

    // Create an invisible link element to trigger the download
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = pdfUrl.split("/").pop(); // Sets the file name to the PDF's name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [playingVideoSrc, setPlayingVideoSrc] = useState(null);

  // Handle video play
  const handleVideoPlay = (videoSrc) => {
    // Set the video source to play or stop if the same video is clicked
    setPlayingVideoSrc(playingVideoSrc === videoSrc ? null : videoSrc);
  };

  // Handle closing the video player
  const handleCloseVideo = (event) => {
    event.stopPropagation();
    setPlayingVideoSrc(null);
  };

  const [showEditTimeBox, setShowEditTimeBox] = useState(false);

  const handleEditTime = () => {
    setShowEditTimeBox(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddMedicien = () => {
    setShowAddMedicienSection(true);
  };

  const userId = useSelector((state) => state.auth?.userId || null);

  async function fetchReminders() {
    try {
      setLoading(true);
      if (userId) {
        const reminders = await reminderService.getReminder(userId);
        setReminderList(reminders);
      }
    } catch (error) {
      console.error("Error in fetchReminders:", error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchReminders();
  }, [userId]);

  const tablets = [
    {
      medication_id: 1,
      tabletLogo: "Glimisave M0.5X",
    },
    {
      medication_id: 2,
      tabletLogo: "Glimisave M1X",
    },
    {
      medication_id: 3,
      tabletLogo: "Glimisave M2X",
    },
    // {
    //   medication_id: 4,
    //   tabletLogo: tabletLogo4,
    // },
  ];

  const pauseReminderFunCall = () => {
    alert("Pause Reminder Function Called");
  };

  const handleOpenArticleTab = () => {
    setArticleTabOpen(true);
    setVideosTabOpen(false);
  };

  const handleOpenVideoTab = () => {
    setArticleTabOpen(false);
    setVideosTabOpen(true);
  };

  const getCurrentTabReminders = () => {
    switch (value) {
      case 0:
        return reminderList.morning || [];
      case 1:
        return reminderList.afternoon || [];
      case 2:
        return reminderList.evening || [];
      default:
        return [];
    }
  };

  const currentTabReminders = getCurrentTabReminders();

  const addBtn = (
    <ThemeProvider theme={theme}>
      <Stack
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"90vh"}
        gap={"10px"}
      >
        <Button
          sx={{
            bgcolor: "#F37957",
            height: "50px",
            borderRadius: "50%",
            padding: "0px",
            color: "white",
            width: "30px",
            fontSize: "25px",
          }}
          onClick={handleAddMedicien}
        >
          <IoMdAdd />
        </Button>
        <Typography
          color={"#95969C"}
          fontSize={"14px"}
          variant="p"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Add Your Medicines for daily Reminders
        </Typography>
      </Stack>
    </ThemeProvider>
  );

  let tabSection;

  switch (value) {
    case 2:
      tabSection = (
        <LearningSection
          articleTabOpen={articleTabOpen}
          videosTabOpen={videosTabOpen}
          handleOpenArticleTab={handleOpenArticleTab}
          handleOpenVideoTab={handleOpenVideoTab}
          handleDownload={handleDownload}
          handleVideoPlay={handleVideoPlay}
          handleCloseVideo={handleCloseVideo}
          playingVideoSrc={playingVideoSrc}
          pdfImg={pdfImg}
          videoImg={videoImg}
        />
      );
      break;

    case 1:
      tabSection = "Sugar Section";
      break;

    default:
      tabSection = (
        <ReminderSection
          reminderList={reminderList}
          eveningImg={eveningImg}
          theme={theme}
          checked={checked}
          handleToggleChange={handleToaggleChange}
        />
      );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <Box
          position={"fixed"}
          zIndex={"2"}
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            marginTop: "65px",
            paddingTop: "10px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#F47458",
              },
            }}
            centered
          >
            <Tab
              label="Med. Reminders"
              sx={{
                fontSize: "13px",
                width: "33.3%",
                fontWeight: "600",
                padding: "5px",
                textTransform: "none",
              }}
            />
            <Tab
              label="Sugar Readings"
              sx={{
                fontSize: "13px",
                width: "33.3%",
                fontWeight: "600",
                padding: "5px",
                textTransform: "none",
              }}
            />
            <Tab
              label="Learnings"
              sx={{
                fontSize: "13px",
                width: "33.3%",
                fontWeight: "600",
                padding: "5px",
                textTransform: "none",
              }}
            />
          </Tabs>
        </Box>

        {loading ? (
          <Stack display={"flex"} justifyContent={"center"} alignItems={"center"} height={"80vh"}>
            <CircularProgress /> 
          </Stack>
        ) : (
          <>
            {value === 0 && !showAddMedicienSection && currentTabReminders.length === 0 && addBtn}
            {value === 0 && showAddMedicienSection && (
              <AddMedicineSection
                tablets={tablets}
                doseLogo={doseLogo}
                setShowAddMedicienSection={setShowAddMedicienSection}
                fetchReminders={fetchReminders}
              />
            )}
            {value === 0 && currentTabReminders.length > 0 && (
              <ReminderSection
                reminderList={reminderList}
                theme={theme}
                checked={checked}
                handleToggleChange={handleToaggleChange}
                fetchReminders={fetchReminders}
              />
            )}
            {value === 1 && <SugarReading />}
            {value === 2 && (
              <LearningSection
                articleTabOpen={articleTabOpen}
                videosTabOpen={videosTabOpen}
                handleOpenArticleTab={handleOpenArticleTab}
                handleOpenVideoTab={handleOpenVideoTab}
                handleDownload={handleDownload}
                handleVideoPlay={handleVideoPlay}
                handleCloseVideo={handleCloseVideo}
                playingVideoSrc={playingVideoSrc}
                pdfImg={pdfImg}
                videoImg={videoImg}
              />
            )}
          </>
        )}
      </ThemeProvider>
    </>
  );
};

export default Index;
