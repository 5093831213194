import React from 'react';
import { Grid, Button } from '@mui/material';
const reminderService = require('../../api/reminders');

const Admin = () => {
  const handleDownload = async () => {
    try {
      const res = await reminderService.getUsers();

      const csvData = convertToCSV(res);

      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'users_data.csv';

      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading data:', error);
    }
  };

  const convertToCSV = (data) => {
    const headers = ['Name', 'Created On',"Phone no"];
    const csvRows = data.map((row) => [row.name, row.created_on,row.phone_number].join(',')); 

    return [headers.join(','), ...csvRows].join('\n');
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        bgcolor: 'white',
        width: '92%',
        marginLeft: '5px',
        padding: '5px',
        borderRadius: '5px',
        fontSize: '12.5px',
        fontWeight: '600',
        height: '100vh',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleDownload}
        sx={{ fontSize: '12px', fontWeight: '600' }}
      >
        Download Data
      </Button>
    </Grid>
  );
};

export default Admin;
