import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectionComponent({
  selectedValue,
  handleChange,
  sx,
}) {
  return (
    <Box sx={{ minWidth: 120, ...sx }}>
      <FormControl fullWidth>
        <Select
          id="demo-simple-select"
          value={selectedValue}
          onChange={handleChange}
          sx={{
            "& .MuiSelect-select": {
              fontSize: "0.8rem",
              padding: "4px 8px",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  fontSize: "0.8rem",
                },
              },
            },
          }}
        >
          <MenuItem value={"Pre-Breakfast"}>Pre-Breakfast</MenuItem>
          <MenuItem value={"Post-Breakfast"}>Post-Breakfast</MenuItem>
          <MenuItem value={"Pre-Lunch"}>Pre-Lunch</MenuItem>
          <MenuItem value={"Post-Lunch"}>Post-Lunch</MenuItem>
          <MenuItem value={"Pre-Dinner"}>Pre-Dinner</MenuItem>
          <MenuItem value={"Post-Dinner"}>Post-Dinner</MenuItem>
          <MenuItem value={"HbA1c"}>HbA1c</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
