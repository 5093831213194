import React, { useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

const PillRefillReminder = ({
  showPillRefillReminderSection,
  dateValue,
  leftTabletValue,
  remindDaysBefore,
  handleDateChange,
  handleLeftTabletChange,
  handleRemindDayBeforeChange,
  handleSavePillRefillReminder,
}) => {
  if (!showPillRefillReminderSection) return null;

  return (
    <Stack sx={{ padding: '0px 15px' }}>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={{ color: 'grey', fontWeight: '600', fontSize: '14px' }}>
            Start Date
          </Typography>
          <input
            type="date"
            value={dateValue}
            onChange={handleDateChange}
            style={{
              width: '110px',
              padding: '7px',
              border: '1px solid #ccc',
              borderRadius: '6px',
              fontSize: '15px',
              color: 'rgba(2, 1, 3, 0.50)',
              marginTop: '5px',
            }}
          />
        </Box>
        <Box>
          <Typography sx={{ color: 'grey', fontWeight: '600', fontSize: '14px' }}>
            Tablet Left
          </Typography>
          <input
            type="number"
            value={leftTabletValue}
            placeholder={leftTabletValue}
            onChange={handleLeftTabletChange}
            style={{
              width: '110px',
              padding: '7px',
              border: '1px solid #ccc',
              borderRadius: '6px',
              fontSize: '15px',
              color: 'rgba(2, 1, 3, 0.50)',
              marginTop: '5px',
              height: '19px',
            }}
          />
        </Box>
      </Grid>
      <Grid marginTop={'20px'}>
        <Box fontSize={'14px'} fontWeight={'600'} color={'rgba(2, 1, 3, 0.50)'}>
          Remind Me{' '}
          <input
            type="number"
            value={remindDaysBefore}
            placeholder={remindDaysBefore}
            onChange={handleRemindDayBeforeChange}
            style={{
              width: '30px',
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '6px',
              fontSize: '14px',
              color: 'rgba(2, 1, 3, 0.50)',
              marginTop: '5px',
              height: '19px',
            }}
          />{' '}
          Days Before Refill
        </Box>
      </Grid>
      <Box display={'flex'} flexDirection={'row-reverse'}>
        <Button
          sx={{
            bgcolor: '#0059A5',
            color: 'white',
            marginTop: '8px',
            width: '50px',
            marginRight: '4px',
            borderRadius: '7px',
            padding: '4px',
            fontSize: '14.5px',
          }}
          onClick={handleSavePillRefillReminder}
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
};

export default PillRefillReminder;
