import React from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Cooming_soon from "./Components/Cooming_soon";
// import Registration from "./Components/Registration/index"
import Registration from "./Components/Registration"
import Home from "./Components/Home"
import Login from "./Components/Login"
import { useSelector } from "react-redux";
import RegisterPopUp from "./Components/RegisterPopUp";
import ProfileSetting from "./Components/ProfileSetting/ProfileSetting";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const PrivateRoutes = () => {
    return isAuthenticated ? <Outlet /> : <Navigate to="/home" />;
  };

  const PublicRoutes = () => {
    return !isAuthenticated ? <Outlet /> : <Navigate to="/home" />;
  };

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterPopUp />} />
          <Route path="/profileSetting" element={<ProfileSetting />} />
        </Route>

        <Route element={<PrivateRoutes />}></Route>
      </Routes>
    </>
  );
}

export default App;
