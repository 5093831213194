import axiosInstance from "../axiosInstance";

export const createArticle = async (articleData) => {
  try {
    const response = await axiosInstance.post("/api/rem/article", articleData);
    return response.data;
  } catch (error) {
    console.error("Error creating article:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getArticles = async () => {
  try {
    const response = await axiosInstance.get("/api/rem/get-articles");
    return response.data;
  } catch (error) {
    console.error("Error fetching articles:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const createVideo = async (videoData) => {
  try {
    const response = await axiosInstance.post("/api/rem/video", videoData);
    return response.data;
  } catch (error) {
    console.error("Error creating video:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getVideos = async () => {
  try {
    const response = await axiosInstance.get("/api/rem/get-videos");
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error.response ? error.response.data : error.message);
    throw error;
  }
};
