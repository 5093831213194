import { Button, Stack, Typography, Box, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectionComponent from "./SelectionComponent/Index";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import "./SugarReading.scss";
import { useSelector } from "react-redux";
import { getSugarRecords, createSugarRecord } from "../../api/sugarReading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SugarReading = () => {
  const [showAddReadingSection, setShowAddReadingSection] = useState(false);
  const [showSavedReadingSection, setShowSavedReadingSection] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [readingType, setReadingType] = useState("");
  const [readingValue, setReadingValue] = useState("");
  const [selectedRange, setSelectedRange] = useState("last7days"); // Default range
  const [readingData, setReadingData] = useState([]);
  const userId = useSelector((state) => state.auth?.userId || null);
  const [sugarRecords, setSugarRecords] = useState([]);

  useEffect(() => {
    if (userId) {
      const data = {
        user_id: userId,
        filter: selectedRange,
      };

      const fetchSugarRecords = async () => {
        try {
          const response = await getSugarRecords(data);
          if (response.status === 200) {
            setSugarRecords(response.data);
          } else {
            toast.error("Something went wrong. Please try again later.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (error) {
          console.error("Error fetching sugar records:", error);

          if (error.response && error.response.status === 404) {
            toast.error("Records not found.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (error.response && error.response.status === 500) {
            toast.error("Server error. Please try again later.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("An unexpected error occurred. Please try again later.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      };

      fetchSugarRecords();
    }
  }, [userId, selectedRange]);

  useEffect(() => {
    if (sugarRecords.length === 0) {
      setShowAddReadingSection(true);
      setShowSavedReadingSection(false);
    } else {
      setReadingData(sugarRecords);
      setShowAddReadingSection(false);
      setShowSavedReadingSection(true);
    }
  }, [sugarRecords]);

  const handleRangeSelection = (range) => {
    setSelectedRange(range);
  };

  const ranges = ["last7days", "last1month", "last3months"];

  const handleReadingTypeChange = (event) => {
    setReadingType(event.target.value);
  };

  const handleReadingValueChange = (event) => {
    setReadingValue(event.target.value);
  };

  const handleAddNewReading = () => {
    setShowSavedReadingSection(false);
    setShowAddReadingSection(true);
  };

  const handleSave = async () => {
    if (!userId) {
      toast.error("Please Login for create reading", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!readingType || !selectedDate || !selectedTime || !readingValue) {
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const newReading = {
      user_id: userId,
      reading_type: readingType,
      selected_date: selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : null,
      selected_time: selectedTime ? dayjs(selectedTime).format("HH:mm:ss") : null,
      reading_value: readingValue,
    };

    try {
      const response = await createSugarRecord(newReading);
      if (response.status === 201) {
        const createdRecord = response.data;
        console.log("Created Record:", createdRecord);

        setReadingData([...readingData, createdRecord]);

        setSelectedDate(null);
        setSelectedTime(null);
        setReadingType("");
        setReadingValue("");

        setShowAddReadingSection(false);
        setShowSavedReadingSection(true);
      } else {
        toast.error("Failed to save reading. Please try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error saving reading:", error);

      if (error.response && error.response.status === 400) {
        toast.error("Invalid data provided. Please check your input.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (error.response && error.response.status === 404) {
        toast.error("Resource not found. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (error.response && error.response.status === 500) {
        toast.error("Server error. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const getColor = (reading) => {
    if (reading === "Pre-Breakfast" || reading === "Post-Breakfast") {
      return "#997D34";
    } else if (reading === "Post-Lunch" || reading === "Pre-Lunch") {
      return "#00A859";
    } else if (reading === "Pre-Dinner" || reading === "Post-Dinner") {
      return "#6941C6";
    }
  };

  const getBackgroundColor = (reading) => {
    if (reading === "Pre-Breakfast" || reading === "Post-Breakfast") {
      return "#FDEFD9";
    } else if (reading === "Post-Lunch" || reading === "Pre-Lunch") {
      return "#B6E8C7";
    } else if (reading === "Pre-Dinner" || reading === "Post-Dinner") {
      return "#F1E7FF";
    }
  };

  const getWidth = (reading) => {
    if (reading === "Pre-Breakfast" || reading === "Post-Breakfast") {
      return "105px";
    } else if (reading === "Post-Lunch" || reading === "Pre-Lunch") {
      return "80px";
    } else if (reading === "Pre-Dinner" || reading === "Post-Dinner") {
      return "85px";
    }
  };

  const formatDate = (date) => {
    if (!date) return { weekday: "N/A", date: "N/A" };
    const formattedDate = dayjs(date);
    return {
      weekday: formattedDate.format("dddd"),
      date: formattedDate.format("DD-MM-YYYY"),
    };
  };

  const formatTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const formattedTime = dayjs().hour(hours).minute(minutes).second(seconds).format("hh:mm A");
    return time ? formattedTime : "N/A";
  };

  let showCurrentSection;

  if (showAddReadingSection) {
    showCurrentSection = (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "TimePicker"]}>
          <Stack spacing={2.5} width="100%" maxWidth="400px" bgcolor="#fff" borderRadius="8px">
            <Typography variant="body2" fontWeight="bold" fontFamily="Poppins" color={"#4E4E55"}>
              Record Your Readings
            </Typography>
            <Box>
              <Typography
                variant="body2"
                gutterBottom
                color="#707179"
                sx={{ fontSize: "0.875rem" }}
              >
                Select Reading Type
              </Typography>
              <Box sx={{ width: "95%", padding: "0" }}>
                <SelectionComponent
                  selectedValue={readingType}
                  handleChange={handleReadingTypeChange}
                  sx={{ width: "100%" }}
                />
              </Box>
            </Box>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  gutterBottom
                  color="#707179"
                  sx={{ fontSize: "0.875rem" }}
                >
                  Select Date
                </Typography>
                <DatePicker
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  format="DD-MM-YYYY"
                  sx={{
                    width: "90%",
                    padding: "0",
                    "& .MuiInputBase-root": {
                      fontSize: "0.75rem",
                      padding: "0",
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  gutterBottom
                  color="#707179"
                  sx={{ fontSize: "0.875rem" }}
                >
                  Select Time
                </Typography>
                <DemoItem valueType="time">
                  <TimePicker
                    value={selectedTime}
                    onChange={(newValue) => setSelectedTime(newValue)}
                    sx={{
                      width: "90%",
                      padding: "0",
                      "& .MuiInputBase-root": {
                        fontSize: "0.75rem",
                        padding: "0",
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px",
                      },
                    }}
                  />
                </DemoItem>
              </Grid>
            </Grid>

            <Box>
              <Typography variant="body2" gutterBottom sx={{ fontSize: "0.875rem" }}>
                Enter Reading
              </Typography>
              <Box display="flex" alignItems={"end"}>
                <TextField
                  type="number"
                  placeholder="150.85"
                  variant="outlined"
                  size="small"
                  value={readingValue}
                  onChange={handleReadingValueChange}
                  sx={{
                    marginRight: "8px",
                    width: "50%",
                    "& .MuiInputBase-root": {
                      height: "30px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "0 8px",
                      fontSize: "0.875rem",
                    },
                  }}
                />
                <Typography variant="body2" color="#707179" sx={{ fontSize: "0.875rem" }}>
                  {readingType === "HbA1c" ? "%" : "mg/dl"}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
              <Button
                variant="outlined"
                sx={{
                  padding: "5px 8px",
                  height: "30px",
                  fontSize: "12px",
                  width: "48%",
                  color: "#0059A5",
                  border: "2px solid #0059A5",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
                onClick={() => {
                  setShowAddReadingSection(false);
                  setShowSavedReadingSection(true);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "5px 8px",
                  height: "30px",
                  fontSize: "12px",
                  width: "48%",
                  bgcolor: "#0059A5",
                  marginTop: "10px",
                }}
                onClick={handleSave}
              >
                Save Reading
              </Button>
            </Box>
          </Stack>
        </DemoContainer>
      </LocalizationProvider>
    );
  } else if (showSavedReadingSection) {
    showCurrentSection = (
      <Grid display={"flex"} alignItems={"flex-start"} width={"100%"}>
        <Stack width="100%" maxWidth="400px" bgcolor="#fff" borderRadius="8px" textAlign={"start"}>
          <Typography
            variant="body2"
            fontWeight="bold"
            fontFamily="Poppins"
            color={"#4E4E55"}
            textAlign={"start"}
          >
            Recent Readings
          </Typography>

          <Box display="flex" marginTop="8px" gap={"10px"}>
            {ranges.map((range, index) => (
              <Button
                key={index}
                variant={selectedRange === range ? "contained" : "outlined"}
                onClick={() => handleRangeSelection(range)}
                sx={{
                  margin: "5px 0px",
                  textTransform: "none",
                  padding: "5px 10px",
                  fontSize: "11px",
                  borderRadius: "20px",
                  color: selectedRange === range ? "white" : "#FF725E",
                  border: "1px solid #FF725E",
                  fontWeight: "600",
                  backgroundColor: selectedRange === range ? "#FF725E" : "transparent",
                  "&:hover": {
                    backgroundColor:
                      selectedRange === range ? "#FF725E" : "rgba(255, 114, 94, 0.04)",
                  },
                }}
              >
                {range}
              </Button>
            ))}
          </Box>

          <Box mt={2}>
            {readingData && readingData.length > 0 ? (
              readingData.map((reading, index) => {
                const { weekday, date } = formatDate(reading.selected_date);
                return (
                  <Box
                    key={index}
                    mb={1.8}
                    p={1}
                    borderRadius="9px"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    bgcolor={"white"}
                    boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
                  >
                    <Stack display={"flex"} flexDirection={"column"} gap={"5px"}>
                      <Typography variant="body2" fontWeight="bold" color={"#4E4E55"}>
                        <div>{date}</div>
                      </Typography>
                      <Typography variant="body2" color={"#AFAFAF"} display={"flex"}>
                        <div style={{ color: "#707179" }}>{weekday}, </div>
                        {formatTime(reading.selected_time)}
                      </Typography>
                      <Typography
                        variant="body2"
                        marginTop={"8px"}
                        padding={"2px 7px"}
                        borderRadius={"4px"}
                        display="inline-flex"
                        flexShrink={0}
                        bgcolor={getBackgroundColor(reading.reading_type)}
                        color={getColor(reading.reading_type)}
                        width={getWidth(reading.reading_type)}
                      >
                        {` ${reading.reading_type || "N/A"}`}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="body2">
                        <span style={{ fontSize: "2.0rem" }}>
                          {` ${reading.reading_value || "N/A"}`}
                        </span>
                        <span style={{ fontSize: "0.875rem" }}>
                          {" "}
                          {reading.reading_type === "HbA1c" ? "%" : "mg/dl"}
                        </span>
                      </Typography>
                    </Stack>
                  </Box>
                );
              })
            ) : (
              <Typography variant="body2" color="#707179">
                No readings available.
              </Typography>
            )}
          </Box>

          {readingData.length > 0 && (
            <Button
              sx={{
                bgcolor: "#0059A5",
                borderRadius: "8px",
                width: "165px",
                textTransform: "none",
                color: "white",
                position: "fixed",
                zIndex: "200",
                bottom: "25px",
                right: "15px",
              }}
              onClick={handleAddNewReading}
            >
              + Add New Reading
            </Button>
          )}
        </Stack>
      </Grid>
    );
  }

  return (
    <Grid container paddingTop="140px" paddingLeft="10px" paddingRight="10px">
      {showCurrentSection}
    </Grid>
  );
};

export default SugarReading;
