import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    role: null,
    userId: null,
    userName: null,
  },
  reducers: {
    login: (state,action) => {
      state.isAuthenticated = true;
      state.userName = action.payload.userName;
      state.userId = action.payload.userId;
      state.phone_number = action.payload.phone_number;
      // state.role = action.payload.role;
      // state.userId = action.payload.userId;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.userId = null;
      state.userName = null;
      state.phone_number = null;  
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
