import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography, Box, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { RxCross2 } from 'react-icons/rx';
import "./index.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const learningService = require("../../api/learning");

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
});

const LearningSection = ({
  articleTabOpen,
  videosTabOpen,
  handleOpenArticleTab,
  handleOpenVideoTab,
  handleDownload,
  handleVideoPlay,
  handleCloseVideo,
  playingVideoSrc,
  pdfImg,
  videoImg,
}) => {
  const [articles, setArticles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('English');

  useEffect(() => {
    async function fetchArticles() {
      try {
        const articlesData = await learningService.getArticles();
        setArticles(articlesData);
      } catch (error) {
        console.error("Error in fetchArticles:", error.message);
      }
    }
    fetchArticles();
  }, []);

  // useEffect(() => {
  //   async function fetchVideos() {
  //     try {
  //       const videosData = await learningService.getVideos();
  //       setVideos(videosData);
  //     } catch (error) {
  //       console.error("Error in fetchVideos:", error.message);
  //     }
  //   }
  //   fetchVideos();
  // }, []);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const filteredArticles = articles.filter(article => article.language === selectedLanguage);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        height={"100vh"}
        paddingTop={"150px"}
        paddingLeft={"12px"}
        paddingRight={"12px"}
        sx={{ bgcolor: "#F5F5F5" }}
      >
        <Stack>
          <Typography
            sx={{ fontWeight: "500", color: "#4E4E55", fontSize: "14px" }}
          >
            Understand And Manage Your Diabetes Better With Our Educational Resources
          </Typography>
          <Box
            marginTop={"13px"}
            padding={"5px 0px"}
            display={"flex"}
            gap={"10px"}
          >
            <Button
              variant={articleTabOpen ? "contained" : "outlined"}
              sx={{
                height: "20px",
                bgcolor: articleTabOpen ? "#0059A5" : "white",
                fontSize: "12px",
                fontWeight: "500",
                borderRadius: "15px",
                padding: "15px 15px",
                color: articleTabOpen ? "white" : "#0059A5",
                border: "1.6px solid #0059A5",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: articleTabOpen
                    ? "#0059A5"
                    : "rgba(0, 89, 165, 0.04)",
                },
              }}
              onClick={handleOpenArticleTab}
            >
              Articles
            </Button>

            <FormControl
              sx={{
                minWidth: 80,
                height: "20px",
                borderRadius: "15px",
                "& .MuiInputBase-root": {
                  height: "100%",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: selectedLanguage ? "#0059A5" : "inherit",
                  border: "1.6px solid #0059A5",
                  padding: "15px 15px",
                  borderRadius: "15px",
                  textTransform: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0059A5",
                },
                "& .MuiSvgIcon-root": {
                  color: "#0059A5",
                }
              }}
            >
              {/* <InputLabel id="language-select-label">Language</InputLabel> */}
              <Select
                labelId="language-select-label"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                label="Language"
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Hindi">Hindi</MenuItem>
                <MenuItem value="Gujarati">Gujarati</MenuItem>
                <MenuItem value="Marathi">Marathi</MenuItem>
                <MenuItem value="Punjabi">Punjabi</MenuItem>
                <MenuItem value="Tamil">Tamil</MenuItem>
                <MenuItem value="Telugu">Telugu</MenuItem>
                <MenuItem value="Kannada">Kannada</MenuItem>
                <MenuItem value="Malayalam">Malayalam</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {articleTabOpen ? (
            <Stack
              margin={"15px 0px"}
              display={"flex"}
              flexDirection={"column"}
              gap={"10px"}
            >
              {filteredArticles.map((article, index) => (
                <Grid
                  key={index}
                  display={"flex"}
                  padding={"10px"}
                  gap={"10px"}
                  borderRadius={"5px"}
                  sx={{ border: "1px solid #E9EAEE", bgcolor: "white" }}
                  onClick={() => handleDownload(article.link)}
                >
                  <Box>
                    <img src={pdfImg} height={"30px"} alt="PDF icon" />
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
                    <Typography
                      color={"#707179"}
                      fontSize={"13px"}
                      fontWeight={"600"}
                    >
                      {article.title}
                    </Typography>
                    <Typography color={"#BCBCC0"} fontSize={"12px"}>
                      Tap to download the full article
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Stack>
          ) : (
            <Stack
              margin={"15px 0px"}
              display={"flex"}
              flexDirection={"column"}
              gap={"10px"}
            >
              {/* {videos.map((video, index) => (
                <Grid
                  key={index}
                  display={"flex"}
                  padding={"10px"}
                  flexDirection={"column"}
                  gap={"10px"}
                  borderRadius={"5px"}
                  sx={{
                    border: "1px solid #E9EAEE",
                    bgcolor: "white",
                    position: "relative",
                  }}
                  onClick={() => handleVideoPlay(video.link)}
                >
                  <Grid item display={"flex"} gap={"10px"}>
                    <Box>
                      <img src={videoImg} height={"30px"} alt="Video icon" />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"0px"}>
                      <Typography
                        color={"#707179"}
                        fontSize={"13px"}
                        fontWeight={"600"}
                      >
                        {video.title}
                      </Typography>
                      {playingVideoSrc === video.link ? (
                        ""
                      ) : (
                        <Typography color={"#BCBCC0"} fontSize={"12px"}>
                          Tap to watch the full video
                        </Typography>
                      )}
                    </Box>

                    {playingVideoSrc === video.link && (
                      <Box
                        position={"absolute"}
                        right={"30px"}
                        fontSize={"20px"}
                        color={"grey"}
                      >
                        <RxCross2 onClick={handleCloseVideo} />
                      </Box>
                    )}
                  </Grid>

                  {playingVideoSrc === video.link && (
                    <Grid item>
                      <video width="100%" height="100%" controls>
                        <source src={video.link} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Grid>
                  )}
                </Grid>
              ))} */}
            </Stack>
          )}
        </Stack>
      </Grid>
    </ThemeProvider>
  );
};

export default LearningSection;
